// var apis = [
//     {
//         name: 'Nota Fiscal v1',
//         url: 'https://api.nfe.io/v1/swagger.json'
//     },
//     {
//         name: 'Nota Fiscal v2 (Beta)',
//         url: '../docs/jsons/productinvoice.json'
//     },
//     {
//         name: 'Pessoa Jurídica v1',
//         url: 'https://legalentity.api.nfe.io/v1/swagger.json'
//     },
//     {
//         name: 'Pessoas Físicas v1',
//         url: 'https://naturalperson.api.nfe.io/v1/swagger.json'
//     },
//     {
//         name: 'SEFAZ NF v1',
//         url: 'https://nfe.api.nfe.io/v1/swagger.json'
//     },
//     {
//         name: 'Endereços v1',
//         url: 'https://address.api.nfe.io/v1/swagger.json'
//     }
// ];

// var sandBoxes = [
//     {
//         name: 'Nota Fiscal v1',
//         url: 'https://api.nfe.io/'
//     },
//     {
//         name: 'Nota Fiscal v2 (Beta)',
//         url: 'http://dev-dfe.westus2.cloudapp.azure.com/_internal/v1/mgr/swagger/'
//     },
//     {
//         name: 'Pessoa Jurídica v1',
//         url: 'https://legalentity.api.nfe.io/'
//     },
//     {
//         name: 'Pessoas Físicas v1',
//         url: 'https://naturalperson.api.nfe.io'
//     },
//     {
//         name: 'SEFAZ NF v1',
//         url: 'https://nfe.api.nfe.io'
//     },
//     {
//         name: 'Endereços v1',
//         url: 'https://address.api.nfe.io/'
//     }
// ];

// // initially render first API
// Redoc.init(apis[0].url);
// // Redoc.init('../api/docs/swagger.json');

// function onClick() {
//     var url = this.getAttribute('data-link');
//     Redoc.init(url);
// }


// dynamically building navigation items
// var $list = document.getElementById('links_container');
// apis.forEach(function (api) {
//     var $listitem = document.createElement('li');
//     $listitem.setAttribute('data-link', api.url);
//     $listitem.className = "item-link";
//     $listitem.innerHTML = '<i class="icon-book"></i>' + api.name;
//     $listitem.addEventListener('click', onClick);
//     $list.appendChild($listitem);
// });

// var $sandBox = document.getElementById('sandbox_container');
// sandBoxes.forEach(function (sandBox) {
//     var $listSandbox = document.createElement('li');
//     // $listSandbox.setAttribute('data-link', sandBox.url);
//     // $listSandbox.setAttribute('onchange', 'location = this.value;');
//     $listSandbox.className = "item-link";
//     $listSandbox.innerHTML = '<a target="_blank" href='+ sandBox.url +'><i class="icon-json">{ }</i>'+ sandBox.name +'</a>';
//     // $listSandbox.addEventListener('click', onClick);    
//     $sandBox.appendChild($listSandbox);
// });

// $(window).scroll(function () {
//     var sc = $(window).scrollTop(),
//         header = $('header');
//     if (sc > 100) {
//         header.addClass("fixed");
//     } else {
//         header.removeClass("fixed");
//     }
// });

var JSCODE = JSCODE || {};

$(document).ready(function () {
    $(".htXgYR").css("display", "none");
    JSCODE.init();
});

$(window).resize(function () {
});

JSCODE.init = function () {
    this.scroll_fixed();
};


JSCODE.scroll_fixed = function () {
    var $header = $('*[data-scope="header"]'),
        $groupHeader = $('*[data-scope="group-header"]', $header),
        $distance = $('*[data-scope="content"]').offset().top,
        $header_fixed = false;

    $(window).scroll(function () {

        if ($(document).scrollTop() > $distance) {
            if ($header_fixed == false) {
                $groupHeader.hide().addClass('fixed').fadeIn(200);
            }
            $header_fixed = true;
        } else {
            if ($header_fixed == true) {
                $groupHeader.fadeOut(200, function () {
                    $groupHeader.removeClass('fixed').show();
                });
            }
            $header_fixed = false;
        }

    });
}


$(document).on('click', '.btClose', function (e) {
    e.preventDefault();
    $('.header .menu').removeClass('on');
    $('body').css('overflow', 'auto');
    return;
});

$(document).on('click', '.btMenu', function (e) {
    e.preventDefault();
    $('.header .menu').addClass('on');
    $('body').css('overflow', 'hidden');
    return;
});

$(document).on('click', '.menu', function (e) {
    if (e.offsetX < 0) {
        $('.header .menu').removeClass('on');
        $('body').css('overflow', 'auto');
    }
});

$(document).on('click', '.menu a', function (e) {
    var id = $(this).attr('id');
    if (id != "dev") {
        $('.header .menu').removeClass('on');
        $('body').css('overflow', 'auto');
    }
});

